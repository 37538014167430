// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { KeycloakConfig } from 'keycloak-js';

export const environment: ApizConfig = {
  basehref: '/',
  configUrl: 'https://config-server.std.dev-k8s.apizsolutions.com/iupics-config/default/develop',
  production: true,
  appName: 'Apiz',
  CUST_version: 'dev-a6b8919d',
  STD_version: '4.1.2',
  default_language: 'fr_FR',
  webSiteUrl: 'https://std.dev-k8s.apizsolutions.com/',
  available_languages: ['ar_TN', 'ca_ES', 'en_GB', 'en_US', 'en', 'es_ES', 'fr_FR', 'fr', 'nl_NL'],
  config: {
    perfLogActive: true,
    backend: {
      auth: {
        url: 'https://auth-server.std.dev-k8s.apizsolutions.com',
        realm: 'apiz',
        clientId: 'iupics'
      },
      socketcluster: {
        hostname: 'https://socket.std.dev-k8s.apizsolutions.com',
        port: undefined,
        secure: true
      }
    }
  },
  constant: null,
  themes: null
};

export interface ApizConfig {
  production: boolean;
  config: IAppConfig;
  constant: { [key: string]: any };
  themes: { [key: string]: any };
  [key: string]: any;
}

export interface IAppConfig {
  perfLogActive: boolean;
  backend: {
    auth?: KeycloakConfig;
    docserver?: {
      url: string;
      [key: string]: any;
    };
    socketcluster?: {
      hostname: string;
      port: number | undefined;
      secure: boolean;
      [key: string]: any;
    };
    version?: {
      url: string;
      [key: string]: any;
    };
    ws?: {
      url: string;
      paths: { [key: string]: string };
      [key: string]: any;
    };
  };
}
